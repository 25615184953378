import React from 'react'
import Layout from '../components/Layout'
import { connect } from 'react-redux';
import SEO from '../components/seo'
import DownloadsStyle from './downloads.module.css'
import { Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons';
import s_motion_logo from '../assets/s-motion-logo.png';
import s_board_logo from '../assets/s-board-logo-64px.png';
import old_s_board_logo from '../assets/old_s-board-logo-64px.png'
import { navigate } from '@reach/router';
import { Link } from 'gatsby'
import {retrieveMyAccount, registerProduct, clearError, refreshed, needsRefresh} from '../redux/user.redux';

const card = (name, learnMoreUrl, logo, version, description, updateLog, systemReq, macURL, win64URL, win86URL, isNew) => (
    <div className={DownloadsStyle.card}>
        {isNew ? <div className={DownloadsStyle.newBadge}>New</div> : null}
        {isNew ? <br/> : null}
        <Link to={learnMoreUrl}><img src={logo} style={{marginBottom: '6px', marginTop: '16px'}}/></Link>
        <h2 style={{textTransform: 'uppercase', marginTop: '1.2rem'}}>{name}</h2>
        <p style={{fontWeight: 300, fontSize: '14px'}}>{'v'+version}</p>
        <p style={{fontSize: '14px', textAlign: 'left', marginBottom: '2rem'}}>{description}</p>
        <div style={{textAlign:"left", marginTop: '16px', marginBottom: '3rem'}}>
            {updateLog.split('\n').map((line, i) => {
                return (
                    <p key={`log${i}`} style={{fontSize: '14px', margin: 0, textAlign: 'left'}}>
                        {line}
                    </p>
                )
            })}
        </div>
        <p style={{textAlign: 'left', marginBottom: '6px'}}>
            System Requirements
        </p>
        <ul style={{textAlign: 'left', marginLeft: '14px', marginTop: '12px'}}>
            {systemReq.split('\n').map((line,i) => {
                return (
                    <li key={i} style={{fontSize: '14px', fontFamily: `'Roboto', sans-serif`, marginBottom: '2px'}}>
                        {line}
                    </li>
                )
            })}
        </ul>
        <Grid container spacing={3}>
            <Grid item xs>
                <a href={`https://sonic-instruments.co/softwares/${macURL}`}>
                    <button className={DownloadsStyle.macBtn}>
                        <FontAwesomeIcon icon={faApple} size="1x" style={{marginRight: '8px'}}/>
                        Mac
                    </button>
                </a>
            </Grid>
            <Grid item xs>
                <a href={`https://sonic-instruments.co/softwares/${win64URL}`}>
                    <button className={DownloadsStyle.winBtn}>
                        <FontAwesomeIcon icon={faWindows} size="1x" style={{marginRight: '8px'}}/>
                        Win 64bit
                    </button>
                </a>
            </Grid>
            {win86URL ? 
            <Grid item xs>
                <a href={`https://sonic-instruments.co/softwares/${win86URL}`}>
                    <button className={DownloadsStyle.winBtn}>
                        <FontAwesomeIcon icon={faWindows} size="1x" style={{marginRight: '8px'}}/>
                        Win 32bit
                    </button>
                </a>
            </Grid> : null
            }
        </Grid>
    </div>
)

class Downloads extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newProductSN: '',
            newProductSNError: false,
        }
    }

    componentDidUpdate() {
        if (this.props.user.err) {
            alert(this.props.user.err.data.message);
            this.props.clearError();
        }
        if (this.props.user.needsRefresh) {
            this.props.retrieveMyAccount();
            this.props.refreshed();
        }
    }

    handleChange = name => event => {
        event.persist();
        this.setState(state => ({ ...state, [name]: event.target.value, [`${name}Error`]: false }));
    };

    handleRegister = () => {
        if (!this.state.newProductSN) {
            this.setState({newProductSNError: true});
            return;
        }
        this.props.registerProduct({serialNumber: this.state.newProductSN});
    }

    productCards = () => {
        return (
            <React.Fragment>
                {card(
                    'S-Board 2.1',
                    '/s-board',
                    s_board_logo, 
                    '2.1.0', 
                    "",
                    `New at 2.1.0:
                    • 5 new modules added.
                    • Improved GUI rendering performance, 30% less CPU usage.
                    • Added CV input to dieRoboter's nasal parameter and formant parameter.
                    • Multiple GUI rendering fixes on Windows.
                    • Fixed automation parameter mappings when adding/removing modules.
                    • Improved stability.
                    `,
                    `Windows 7 or later
                    macOS 10.13 or later (macOS 11 Big Sur and Apple Silicon compatable)
                    Intel® Core™2 Duo processor. Intel® Core™ i5 processor or faster recommended.
                    2 GB RAM (4 GB or more recommended)`,
                    'S-Board_2.1.0.pkg',
                    'S-Board_2.1.0.zip',
                    null,
                    false,
                )}
                {card(
                    'S-Motion 2.0.4',
                    '/mo-band',
                    s_motion_logo, 
                    '2.0.4', 
                    `* Requires MO-Band 2.0 or newer, older MO-Bands are not supported`,
                    `New at 2.0.4:
                    • Completely redesigned interface, more intuitive and elegant.
                    • Accessible for visually impaired users.
                    • Improved motion tracking response rate.
                    • Send/receive MIDI clock to/from DAWs and hardwares.
                    • Virtual MIDI keyboard.
                    • Lower CPU usages.`,
                    `Windows 7 or later or macOS 10.13 or later (macOS 10.15 Catalina compatable)
                    Intel® Core™2 Duo processor. Intel® Core™ i5 processor or faster recommended.
                    2 GB RAM (4 GB or more recommended)`,
                    'S-Motion_2.0.4_OSX.zip',
                    'S-Motion_2.0.4.zip',
                    null,
                    false
                )}
                {/* {card(
                    'S-Motion 1.0',
                    '/mo-band',
                    s_motion_logo, 
                    '1.5.0', 
                    `Translate your motion to MIDI control messages, and output them into DAWs and external hardware devices`,
                    `New at 1.5.0:
                    • 3-way motion trigger.
                    • Trigger notes, chords and BPM synced wavetable control.
                    • Upgraded user interface.
                    • Control any number of external MIDI devices simultaneously.
                    • Advanced gesture trigger control interface.`,
                    `Windows 7 or later or macOS 10.13 or later (macOS 10.15 Catalina compatable)
                    Intel® Core™2 Duo processor. Intel® Core™ i5 processor or faster recommended.
                    2 GB RAM (4 GB or more recommended)`,
                    'S-Motion_1.5.dmg',
                    'S-Motion_1.5.0_x64 Setup.zip',
                    'S-Motion_1.5.0_x86 Setup.zip'
                )} */}
                
            </React.Fragment>
        )
    }


    render () {
        
        if (!this.props.auth.isLoggedIn) {
            navigate('/login', {state: {destination: '/downloads'}});
        }
        return (
            <Layout>
                <SEO
                    title='Downloads'
                    keywords={[`music`, `MO-Band`, `beatbox`, `MIDI`, `audio engineering`, `MIDI controller`]}
                />
                <div className={DownloadsStyle.container}>
                    <h1>Download Center</h1>
                    {this.productCards()}
                </div>
            </Layout>
        )
    }
}

const mapStatetoProps = (state) => {return {auth: state.auth, user: state.user}};
const actionCreators = { retrieveMyAccount, registerProduct, clearError, refreshed, needsRefresh };
export default connect(mapStatetoProps, actionCreators)(Downloads);